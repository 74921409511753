<template>
  <section id="login">
    <div class="w1300">

      <div class="login_title">
        <h1 class="txt-light_green">LOGIN</h1>
        <h2>會員登入</h2>
      </div>
      <div class="login_box">
        <form
          action=""
          method="post"
          id="form_login"
          name="form_login"
          @submit.prevent
        >
          <small class="txt-gray">※ 帳號為身分證字號，密碼預設為身份證字號</small>
          <LoginInput
            v-model.trim="user.id_number"
            :item="inputFormat.account"
            :rules="[
              val => !!val || '必填欄位！',
              [/^[A-Z][1-2]\d{8}$/, '身分證格式錯誤']
            ]"
            :maxlength="10"
          />
          <!-- 密碼格式：六位英數混合 -->
          <LoginInput
            v-model.trim="user.password"
            :item="inputFormat.password"
            :rules="[
              val => val.length > 5 || '長度錯誤!',
              [/^([a-zA-Z]+\d+|\d+[a-zA-Z]+)[a-zA-Z0-9]*$/, '密碼格式錯誤']
            ]"
            @toggleInputType="toggleInputType"
          />
          <div class="checkbox_container">
            <!-- 功能：記住我 -->
            <!-- <label for="remember_status">
              <input
                type="checkbox"
                id="remember_status"
                name="remember_status"
                v-model="user.remember"
              >
              <span class="p txt-bold">記住我</span>
              <div class="checkmark"></div>
            </label> -->
            <!-- <router-link to="/login/forget">忘記密碼 ?</router-link> -->
          </div>
          <div class="login_footer">
            <button
              type="button"
              class="btn"
              :class="{ btnDisable: isBtnDisable }"
              @click="onSubmit"
            ><h4>LOGIN</h4></button>
            <router-link to="/application/index" class="apply_link txt-light_green txt-bold">
              入會申請
            </router-link>
          </div>
        </form>
      </div>
      <div class="decorate_img"></div>

    </div>
  </section>
</template>

<script>
import '@/assets/scss/login.scss';
import { mapActions, mapMutations } from 'vuex';
import LoginInput from '@/components/form/LoginInput.vue';
import { login } from '@/lib/http';
import { setAxiosHeaders } from '@/boot/axios';
import VueRouter from 'vue-router';

// eslint-disable-next-line no-unused-vars
const { isNavigationFailure, NavigationFailureType } = VueRouter;

export default {
  title: '登入',
  name: 'LoginIndex',
  data() {
    return {
      user: {
        id_number: '',
        password: '',
        // remember: '',
      },
      inputFormat: {
        account: {
          type: 'text',
          name: '帳號',
        },
        password: {
          type: 'password',
          name: '密碼',
        },
      },
      isBtnDisable: false,
    };
  },
  methods: {
    ...mapActions([
      'login',
      'logout',
      'logoutBeforeLogin',
    ]),
    ...mapMutations([
      'toggleModalInfo',
      'updateModalInfoContent',
    ]),
    toggleInputType() {
      if (this.inputFormat.password.type === 'password') {
        this.inputFormat.password.type = 'text';
      } else if (this.inputFormat.password.type === 'text') {
        this.inputFormat.password.type = 'password';
      }
    },
    onSubmit() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Form/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      if (errorList.indexOf(true) === -1) {
        this.isBtnDisable = true;
        // this.logout();
        this.logoutBeforeLogin();
        setAxiosHeaders(null);

        login(this.user).then((result) => {
          if (result.data.status) {
            this.login(result.data.result.user);
            setAxiosHeaders(this.$cookies.get('token'));
            if (this.$route.query.redirect) {
              this.$router.push({ path: this.$route.query.redirect })
                // eslint-disable-next-line no-unused-vars
                .catch((failure) => {
                  // console.log(isNavigationFailure(failure, NavigationFailureType.redirected));
                });
            } else {
              this.$router.push({ path: '/' });
            }
          } else {
            this.updateModalInfoContent(result.data.message);
            this.toggleModalInfo(true);
          }
          this.isBtnDisable = false;
        });
      } else {
        // console.log('必填欄位尚未完成！');
      }
    },
  },
  components: {
    LoginInput,
  },
};
</script>
